import * as React from 'react';
import { useRef, useState } from 'react';
import './hero.scss';
import { graphql, useStaticQuery } from 'gatsby';
import UIButton from '../controls/UIButton';
import iconCollapse from '../../images/icon-collapse.svg';
import iconExpand from '../../images/icon-expand.svg';
import iconHelp from '../../images/icon-help.svg';
import classNames from 'classnames';
import { Popover } from '@mui/material';

const graphicColors: string[] = [
    '#6eacdb',
    '#397d49',
    '#88b053',
    '#7a87c6',
    '#e4a435',
    '#dfc35a',
    '#c4281b',
    '#a59b8f',
    '#b39fe1'
]

export default function Hero() {
    const data = useStaticQuery(graphql`
        query ExploreQuery {
          exploredata {
            hero {
              subtitle
              title
              how_to_explore_html
            }
          }
        }
    `).exploredata;

    const [expanded, setExpanded] = useState(true);
    const [exploreOpen, setExploreOpen] = useState(false);
    const exploreRef = useRef(null);

    const onClickCollapse = (evt) => {
        evt.stopPropagation();
        setExpanded(false);
    }

    const onClickExpand = () => {
        if (expanded) {
            return;
        }

        setExpanded(true);
    };

    const onClickExplore = (evt) => {
        evt.preventDefault();
        setExploreOpen(!exploreOpen);
    };

    return <section className="explore-hero">
        <div className={classNames('top-content wrapper', { expanded })}>
            <div className="explore-left">
                <h1>{data.hero.title}</h1>
                <div className="subtitle">{data.hero.subtitle}</div>
                <a href="#"
                   className="how-to-explore"
                   ref={exploreRef}
                   onClick={onClickExplore}>
                    <img src={iconHelp} />
                    <span>How to explore the map</span>
                </a>
                <Popover open={exploreOpen}
                         onClose={() => setExploreOpen(false)}
                         anchorEl={exploreRef.current}
                         className="explore-popover"
                         anchorOrigin={{
                             vertical: 'bottom',
                             horizontal: 'left',
                         }}
                         transformOrigin={{
                             vertical: 'top',
                             horizontal: 'left',
                         }}
                >
                    <div className="explore-content"
                         dangerouslySetInnerHTML={{ __html: data.hero.how_to_explore_html }} />
                </Popover>
            </div>
            <div className="explore-right">
                <div className="graphic">
                    {graphicColors.map(color =>
                        <div
                            key={color}
                            style={{ backgroundColor: color }}
                        />)
                    }
                </div>
            </div>
        </div>
        <div className={classNames('collapse-container', { expanded })}
             onClick={onClickExpand}>
            {expanded && <UIButton className="collapse"
                                   color="secondary"
                                   onClick={onClickCollapse}>
              <img src={iconCollapse}/>
              Collapse
            </UIButton>}
            {!expanded && <UIButton className="expand">
              <img src={iconExpand}/>
              Expand
            </UIButton>}
        </div>
    </section>
}