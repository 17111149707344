import * as React from "react"
import { Helmet } from 'react-helmet'
import Layout from '../components/layout';
import { useSiteMetadata } from '../hooks/use-site-metadata';
import './explore.scss';
import Hero from '../components/explore/hero';

const ExplorePage = () => {
    const { title } = useSiteMetadata();

    return (
        <main>
            <Helmet title={title}/>
            <Layout>
                <div className="explore-page">
                    <Hero />
                    <div className="earth-engine">
                        <iframe
                            src="https://earthoutreach.users.earthengine.app/view/dynamicworldapp"
                            title="WRI Dynamic World - Earth Engine"/>
                    </div>
                </div>
            </Layout>
        </main>
    )
}

export default ExplorePage
